type EloIconProps = {
  className?: string
}

export function EloIcon({ className }: EloIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="24"
      viewBox="0 0 63 24"
      fill="none"
      {...{ className }}
    >
      <path
        d="M9.76246 5.30005C10.4677 5.06498 11.2199 4.93961 12.0003 4.93961C15.4167 4.93961 18.2689 7.36553 18.9208 10.5907L23.7633 9.6034C22.6538 4.1247 17.8082 0 12.0003 0C10.6683 0 9.39261 0.216265 8.19531 0.617451L9.76246 5.30005Z"
        fill="#FFCB05"
      />
      <path
        d="M4.04948 20.9901L7.32479 17.2886C5.86422 15.9941 4.94274 14.1041 4.94274 11.9979C4.94274 9.8948 5.86422 8.00483 7.32479 6.71351L4.04948 3.00879C1.56713 5.20905 0 8.42169 0 11.9979C0 15.5772 1.56713 18.7899 4.04948 20.9901Z"
        fill="#00A4E0"
      />
      <path
        d="M18.9208 13.4155C18.2658 16.6376 15.4167 19.0604 12.0035 19.0604C11.2199 19.0604 10.4645 18.935 9.76246 18.6968L8.19531 23.3857C9.39261 23.7838 10.6714 24 12.0035 24C17.805 24 22.6475 19.8816 23.7633 14.4091L18.9208 13.4155Z"
        fill="#EF4123"
      />
      <path
        d="M27.9983 17.4412C27.8385 17.1842 27.6253 16.7736 27.4937 16.4696C26.7258 14.6893 26.6913 12.8464 27.337 11.0786C28.0485 9.13852 29.4087 7.656 31.1671 6.89751C33.3767 5.94782 35.8183 6.13274 37.9371 7.38959C39.2817 8.16062 40.2346 9.35165 40.9586 11.0379C41.0495 11.2542 41.131 11.483 41.2125 11.6804L27.9983 17.4412ZM32.4114 9.7591C30.8411 10.433 30.0325 11.9061 30.2017 13.6299L36.8464 10.7683C35.7024 9.42687 34.2167 8.9818 32.4114 9.7591ZM37.6738 15.9838C37.6707 15.9838 37.6707 15.9869 37.6707 15.9869L37.5328 15.8929C37.1347 16.5354 36.5173 17.0588 35.7369 17.3973C34.2512 18.043 32.8753 17.8769 31.8848 17.0087L31.7939 17.1466C31.7939 17.1466 31.7939 17.1435 31.7908 17.1435L30.1045 19.6666C30.5245 19.958 30.9727 20.2025 31.4429 20.4C33.3046 21.1741 35.2103 21.1365 37.0877 20.3216C38.4449 19.7324 39.5105 18.836 40.2377 17.6982L37.6738 15.9838ZM45.8293 3.18652V17.2093L48.0107 18.0931L46.7727 20.983L44.3655 19.98C43.8264 19.7449 43.4566 19.3876 43.1808 18.9833C42.9144 18.5727 42.7169 18.0054 42.7169 17.2469V3.18652H45.8293ZM51.4772 13.6393C51.4772 12.4452 52.0069 11.3733 52.8407 10.6461L50.6028 8.15122C49.0858 9.49269 48.1298 11.4516 48.1298 13.6331C48.1267 15.8177 49.0826 17.7797 50.5965 19.1212L52.8313 16.6263C52.0007 15.896 51.4772 14.8304 51.4772 13.6393ZM55.4515 17.6167C55.0127 17.6167 54.5864 17.5415 54.1915 17.4099L53.1227 20.5849C53.853 20.8294 54.6335 20.9641 55.4484 20.9641C58.9901 20.9673 61.9458 18.4536 62.6322 15.1156L59.3474 14.4448C58.9744 16.2596 57.3728 17.6199 55.4515 17.6167ZM55.4609 6.31453C54.6491 6.31453 53.8687 6.44617 53.1384 6.68751L54.1978 9.86567C54.5959 9.73403 55.019 9.66194 55.4578 9.66194C57.3822 9.66508 58.987 11.0316 59.3506 12.8495L62.6353 12.185C61.9614 8.84076 59.0058 6.31767 55.4609 6.31453Z"
        fill="black"
      />
    </svg>
  )
}
