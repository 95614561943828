import Image, { ImageProps } from 'next/image'

const StylableNextImage: React.FC<ImageProps> = ({
  className,
  alt,
  ...rest
}) => (
  <div {...{ className }}>
    <Image alt={alt} loading="eager" {...rest} />
  </div>
)

export default StylableNextImage
