import { FC } from 'react'
import { IconsProps } from '.'

const ArrowUpward: FC<IconsProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{ height, width }}
      viewBox="0 0 11 11"
      fill="none"
    >
      <path
        d="M0.333252 5.33325L1.2145 6.2145L4.70825 2.727L4.70825 10.3333L5.95825 10.3333L5.95825 2.727L9.44575 6.22075L10.3333 5.33325L5.33325 0.333252L0.333252 5.33325Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowUpward
