type BoletoIconProps = {
  className?: string
}

export function BoletoIcon({ className }: BoletoIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="24"
      viewBox="0 0 29 24"
      fill="none"
      {...{ className }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.32121 21.1759V2.823H3.43359V21.1759H4.32121Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.65625 2.823V21.1759H7.40486V2.823H5.65625Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.74609 2.823V21.1759H12.1315V2.823H8.74609Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4688 2.823V21.1759H14.1263V2.823H13.4688Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4648 2.823V21.1759H17.2135V2.823H15.4648Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5469 2.823V21.1759H19.2044V2.823H18.5469Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.543 2.823V21.1759H22.2919V2.823H20.543Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6289 2.823V21.1759H25.3775V2.823H23.6289Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.37221 1.41176H27.4294L27.4294 22.5882H1.37221V1.41176ZM27.4294 0C28.1868 0 28.8008 0.632069 28.8008 1.41176V22.5882C28.8008 23.3679 28.1868 24 27.4294 24H1.37221C0.614794 24 0.000782013 23.3679 0.000782013 22.5882V1.41176C0.000782013 0.632069 0.614794 0 1.37221 0H27.4294Z"
        fill="black"
      />
    </svg>
  )
}
