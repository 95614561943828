import StylableNextImage from '@/components/StylableNextImage'
import styled from 'styled-components'

export const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 0.5em clamp(2em, 7.5%, 8em);
  width: clamp(61em, 100%, 100%);
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(101, 101, 101, 0.05),
    0px 5px 10px rgba(61, 61, 61, 0.1);
  z-index: 1300;

  > svg {
    min-width: fit-content;
  }
`

export const LinksContainer = styled.div`
  width: 67%;
  min-width: fit-content;
`

export const Logo = styled(StylableNextImage)`
  transition: all 0.2s;
  display: grid;
  place-items: center;
`
