import { FC } from 'react'
import { IconsProps } from '.'

const ArrowDownward: FC<IconsProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{ height, width }}
      viewBox="0 0 11 11"
      fill="none"
    >
      <path
        d="M10.6667 5.66675L9.7855 4.7855L6.29175 8.273L6.29175 0.666748L5.04175 0.666748L5.04175 8.273L1.55425 4.77925L0.666748 5.66675L5.66675 10.6667L10.6667 5.66675Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowDownward
