import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1.5px solid ${({ theme }) => theme.lightGrey};
  border-radius: 5px;
`

export const StyledButton = styled(Button)`
  transition: box-shadow 0.2s;
  ${({ disabled }) =>
    disabled && 'box-shadow: 0px 2px 6px rgb(0 0 0 / 5%) !important'};

  span > h5 {
    transition: color 0.2s;
    ${({ disabled }) => disabled && 'color: rgba(0, 0, 0, 0.26)'};
  }
`
