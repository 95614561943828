import Add from './Add'
import AcceptedPaymentMethods from './AcceptedPaymentMethods'
import ArrowDown from './ArrowDown'
import ArrowDownward from './ArrowDownward'
import ArrowDropDown from './ArrowDropDown'
import ArrowLeft from './ArrowLeft'
import ArrowRigth from './ArrowRigth'
import ArrowUp from './ArrowUp'
import ArrowUpward from './ArrowUpward'
import Avatar from './Avatar'
import Awaiting from './Awaiting'
import CancelFilled from './CancelFilled'
import CancelOutlined from './CancelOutlined'
import Check from './Check'
import CheckboxChecked from './CheckboxChecked'
import CheckboxUnchecked from './CheckboxUnchecked'
import CheckCircle from './CheckCircle'
import CheckOutlined from './CheckOutlined'
import Close from './Close'
import CloudUpload from './CloudUpload'
import Copy from './Copy'
import Coupon from './Coupon'
import Course from './Course'
import EmojiEmotions from './EmojiEmotions'
import Exclamation from './Exclamation'
import Failed from './Failed'
import File from './File'
import Hamburger from './Hamburger'
import Heart from './Heart'
import HelpCircle from './HelpCircle'
import NotFound from './NotFound'
import Info from './Info'
import Lock from './Lock'
import Location from './Location'
import List from './List'
import MoreHoriz from './MoreHoriz'
import MoreVert from './MoreVert'
import OpenInNew from './OpenInNew'
import Pencil from './Pencil'
import QuestionCircle from './QuestionCircle'
import Question from './Question'
import RadioChecked from './RadioChecked'
import RadioUnchecked from './RadioUnchecked'
import Search from './Search'
import Success from './Success'
import Suitcase from './Suitcase'
import SuitcaseArrow from './SuitcaseArrow'
import Ticket from './Ticket'
import VisibilityOff from './VisibilityOff'
import VisibilityOn from './VisibilityOn'
import Clock from './Clock'

export type IconsProps = {
  width: string
  height: string
  color: string
  className?: string
}

export type IconType =
  | 'AcceptedPaymentMethods'
  | 'Add'
  | 'ArrowDown'
  | 'ArrowDownward'
  | 'ArrowDropDown'
  | 'ArrowLeft'
  | 'ArrowRigth'
  | 'ArrowUp'
  | 'ArrowUpward'
  | 'Avatar'
  | 'Awaiting'
  | 'CancelFilled'
  | 'CancelOutlined'
  | 'Check'
  | 'CheckboxChecked'
  | 'CheckboxUnchecked'
  | 'CheckCircle'
  | 'CheckOutlined'
  | 'Close'
  | 'CloudUpload'
  | 'Copy'
  | 'Coupon'
  | 'Course'
  | 'EmojiEmotions'
  | 'Exclamation'
  | 'Failed'
  | 'File'
  | 'Hamburger'
  | 'Heart'
  | 'HelpCircle'
  | 'NotFound'
  | 'Info'
  | 'Lock'
  | 'Location'
  | 'List'
  | 'MoreHoriz'
  | 'MoreVert'
  | 'OpenInNew'
  | 'Pencil'
  | 'Question'
  | 'QuestionCircle'
  | 'RadioChecked'
  | 'RadioUnchecked'
  | 'Search'
  | 'Success'
  | 'Suitcase'
  | 'SuitcaseArrow'
  | 'Ticket'
  | 'VisibilityOff'
  | 'VisibilityOn'
  | 'Clock'

const Icons = {
  AcceptedPaymentMethods,
  Add,
  ArrowDown,
  ArrowDownward,
  ArrowDropDown,
  ArrowLeft,
  ArrowRigth,
  ArrowUp,
  ArrowUpward,
  Avatar,
  Awaiting,
  CancelFilled,
  CancelOutlined,
  Check,
  CheckboxChecked,
  CheckboxUnchecked,
  CheckCircle,
  CheckOutlined,
  Close,
  CloudUpload,
  Copy,
  Coupon,
  Course,
  EmojiEmotions,
  Exclamation,
  Failed,
  File,
  Hamburger,
  Heart,
  HelpCircle,
  NotFound,
  Info,
  Lock,
  Location,
  List,
  MoreHoriz,
  MoreVert,
  OpenInNew,
  Pencil,
  Question,
  QuestionCircle,
  RadioChecked,
  RadioUnchecked,
  Search,
  Success,
  Suitcase,
  SuitcaseArrow,
  Ticket,
  VisibilityOff,
  VisibilityOn,
  Clock,
}
export default Icons
