import { FC } from 'react'
import { IconsProps } from '.'

const ArrowDropDown: FC<IconsProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{ height, width }}
      viewBox="0 0 10 5"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0L5 5L10 0H0Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowDropDown
