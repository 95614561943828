import React, { ReactNode, useState } from 'react'
import { MenuItemProps } from '../Popper/MenuItem'
import Popper from '../Popper'
import { Container, StyledButton } from './styles'

export type DropDownProps = {
  menuItems: MenuItemProps[]
  label?: ReactNode
  icon: ReactNode
  className?: string
  disabled?: boolean
}

const Dropdown: React.FC<DropDownProps> = ({
  menuItems,
  label,
  icon,
  className,
  disabled = false,
}) => {
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const [open, setOpen] = useState<boolean>(false)
  const closeMenu = () => setOpen(false)
  const openMenu = () => setOpen(true)

  return (
    <Container {...{ className }}>
      <StyledButton
        aria-controls={open ? 'menu-list-grow' : undefined}
        disabled={disabled}
        onClick={openMenu}
        ref={anchorRef}
        aria-haspopup
        data-testid="dropdown"
      >
        {label}
        {icon}
      </StyledButton>
      <Popper {...{ menuItems, anchorRef, open, closeMenu }} />
    </Container>
  )
}

export default Dropdown
