import { FC } from 'react'

import StyledLink from './styles'

export type NavLinkProps = {
  href: string
  text: string
  active: boolean
  onClick?: () => void
}

const NavLink: FC<NavLinkProps> = ({ href, text, active, onClick }) => (
  <StyledLink {...{ onClick, href, active }}>{text}</StyledLink>
)
export default NavLink
