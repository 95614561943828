type SuccessIconProps = {
  className?: string
}
function SuccessIcon({ className }: SuccessIconProps) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ className }}
    >
      <rect width="48" height="48" fill="white" />
      <circle
        cx="24"
        cy="24"
        r="23"
        fill="#3CC47C"
        stroke="#3CC47C"
        strokeWidth="2"
      />
      <path
        d="M19.94 29.9918L14.0117 24.0857L12 26.0898L19.94 34L37 17.0041L34.9883 15L19.94 29.9918Z"
        fill="white"
      />
    </svg>
  )
}
export default SuccessIcon
