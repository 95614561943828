type PixIconProps = {
  className?: string
}

export function PixIcon({ className }: PixIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...{ className }}
    >
      <path
        d="M18.7162 18.3548C17.7741 18.3548 16.89 17.9925 16.2233 17.3258L12.629 13.7314C12.3826 13.485 11.9333 13.485 11.6869 13.7314L8.07804 17.3403C7.41135 18.007 6.52725 18.3693 5.58518 18.3693H4.875L9.44042 22.9347C10.8608 24.3551 13.1797 24.3551 14.6001 22.9347L19.18 18.3548H18.7162Z"
        fill="#32BCAD"
      />
      <path
        d="M5.57233 5.63068C6.5144 5.63068 7.39849 5.99302 8.06519 6.65972L11.674 10.2686C11.9349 10.5295 12.3552 10.5295 12.6161 10.2686L16.225 6.67421C16.8917 6.00751 17.7758 5.64518 18.7178 5.64518H19.1526L14.5727 1.06526C13.1524 -0.355088 10.8334 -0.355088 9.41307 1.06526L4.84766 5.63068H5.57233V5.63068Z"
        fill="#32BCAD"
      />
      <path
        d="M22.9358 9.42726L20.1676 6.65902C20.1096 6.68801 20.0371 6.7025 19.9646 6.7025H18.7037C18.0515 6.7025 17.4138 6.96339 16.9645 7.42717L13.3702 11.0215C13.0368 11.3549 12.5875 11.5288 12.1527 11.5288C11.7034 11.5288 11.2686 11.3549 10.9353 11.0215L7.32641 7.41268C6.86262 6.94889 6.22491 6.68801 5.5872 6.68801H4.03641C3.96394 6.68801 3.90597 6.67352 3.848 6.64453L1.06526 9.42726C-0.355088 10.8476 -0.355088 13.1666 1.06526 14.5869L3.8335 17.3551C3.89147 17.3262 3.94945 17.3117 4.02192 17.3117H5.5727C6.22491 17.3117 6.86262 17.0508 7.31191 16.587L10.9208 12.9781C11.573 12.3259 12.718 12.3259 13.3702 12.9781L16.9645 16.5725C17.4283 17.0363 18.066 17.2972 18.7037 17.2972H19.9646C20.0371 17.2972 20.0951 17.3117 20.1676 17.3407L22.9358 14.5724C24.3561 13.1521 24.3561 10.8476 22.9358 9.42726Z"
        fill="#32BCAD"
      />
    </svg>
  )
}
