import { BoletoIcon } from './boleto'
import { AmexIcon } from './amex'
import { DinnersClubIcon } from './dinnersClub'
import { EloIcon } from './elo'
import { HiperIcon } from './hiper'
import { HipercardIcon } from './hipercard'
import { MasterCardIcon } from './mastercard'
import { PixIcon } from './pix'
import { VisaIcon } from './visa'

function AcceptedPaymentMethodsIcon() {
  return (
    <>
      <MasterCardIcon />
      <VisaIcon />
      <EloIcon />
      <HipercardIcon />
      <HiperIcon />
      <DinnersClubIcon />
      <AmexIcon />
      <BoletoIcon />
      <PixIcon />
    </>
  )
}

export default AcceptedPaymentMethodsIcon
