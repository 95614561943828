import { FC } from 'react'
import { IconsProps } from '.'

const MoreHoriz: FC<IconsProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{ height, width }}
      viewBox="0 0 24 24"
    >
      <path
        d="M6 3C6 1.35 4.65 -2.03258e-07 3 -1.31134e-07C1.35 -5.90104e-08 -2.03258e-07 1.35 -1.31134e-07 3C-5.90104e-08 4.65 1.35 6 3 6C4.65 6 6 4.65 6 3ZM9 3C9 4.65 10.35 6 12 6C13.65 6 15 4.65 15 3C15 1.35 13.65 -5.9666e-07 12 -5.24537e-07C10.35 -4.52413e-07 9 1.35 9 3ZM18 3C18 4.65 19.35 6 21 6C22.65 6 24 4.65 24 3C24 1.35 22.65 -9.90063e-07 21 -9.17939e-07C19.35 -8.45815e-07 18 1.35 18 3Z"
        fill={color}
      />
    </svg>
  )
}

export default MoreHoriz
