import { MenuList, Paper, Popper } from '@material-ui/core'
import styled from 'styled-components'
import { hexToRgba } from '@/lib/utils/hexToRgba'

export const StyledMenuList = styled(MenuList)`
  display: flex;
  flex-direction: column;
  padding: 0;
`
export const Container = styled(Popper)`
  z-index: 1;
  border-radius: 4px;
`

export const StyledButton = styled.button`
  background-color: white;
  border: none;
  text-align: start;
  font-family: 'Roboto', sans-serif;
  padding: 0.8em 1.1em 0.8em 1em;
  width: 100%;
  min-width: 6em;

  font-size: 1em;
  letter-spacing: 0.009em;
  color: ${({ theme }) => theme.darkGrey};
  &:hover {
    background-color: ${({ theme }) => hexToRgba(`${theme.edukRed}`, 8)};
  }
`
export const StyledPaper = styled(Paper)`
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
`
