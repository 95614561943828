import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  display: flex;

  place-items: center;
  place-content: center;

  form {
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.lightGreyy};
    gap: 1em;
    padding: 3em 3.5em;
    border-radius: 8px;
    width: clamp(300px, 50%, 420px);
  }

  button[type='submit'] {
    margin-top: 1em;
  }
`
