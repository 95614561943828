import { useAuth } from '@/lib/context/AuthContext'
import { AmplitudeEventName } from '@/lib/models/enums'
import { User, AmplitudeEvents } from '@/lib/models/types'
import { flattenAndFormatObject } from '@/lib/utils/flattenAndFormatObject'
import {
  Analytics,
  getAnalytics,
  logEvent,
  setUserProperties as firebaseSetUserProperties,
  setUserId as firebaseSetUserId,
} from 'firebase/analytics'
type DispatchEvent = (params: AmplitudeEvents) => void

export type UseAmplitude = () => { dispatchEvent: DispatchEvent }

type Amplitude = {
  getInstance: () => {
    logEvent: (
      eventName: AmplitudeEventName,
      eventProperties?: Record<string, unknown>
    ) => void
    setUserProperties: (userProperties: Record<string, unknown>) => void
    setUserId: (id: string) => void
  }
}

function formatFirebaseEventName(eventName: string) {
  return eventName.replaceAll('-', '_')
}

export const useAmplitude: UseAmplitude = () => {
  const { user, isOperator } = useAuth()

  let amplitude: Amplitude
  let analytics: Analytics | undefined

  const userProperties = createUserProperties(user, isOperator)

  if (typeof window !== 'undefined') {
    amplitude = require('amplitude-js')

    try {
      analytics = getAnalytics()
    } catch {
      console.error('Erro ao instanciar o Firebase Analytics')
    }
  }

  function dispatchFirebaseEvent(
    event: AmplitudeEventName,
    eventProperties?: Record<string, unknown>
  ) {
    if (!analytics) return
    logEvent(
      analytics,
      formatFirebaseEventName(event),
      flattenAndFormatObject(eventProperties)
    )
  }

  const setUserProperties = () => {
    if (!userProperties) return

    const { id, ...rest } = userProperties
    amplitude.getInstance().setUserProperties(rest)
    amplitude.getInstance().setUserId(id)

    if (!analytics) return
    firebaseSetUserProperties(analytics, rest)
    firebaseSetUserId(analytics, id)
  }

  const dispatchEvent: DispatchEvent = ({ event, eventProperties }) => {
    setUserProperties()

    amplitude.getInstance().logEvent(event, eventProperties)

    if (!analytics) return
    dispatchFirebaseEvent(event, eventProperties)
  }

  return { dispatchEvent }
}

function createUserProperties(
  user: User | undefined,
  isOperator: boolean
): (Record<string, unknown> & { id: string }) | undefined {
  if (!user) return

  const { id, email, companySocialName, companyId } = user

  return {
    id,
    email,
    isOperator,
    company: {
      socialName: companySocialName,
      id: companyId,
    },
  }
}
