type HiperIconProps = {
  className?: string
}

export function HiperIcon({ className }: HiperIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      {...{ className }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.55001 4.00118L20.3734 0.117176C22.8282 -0.44957 25.3004 1.09514 25.8672 3.55004L29.7511 20.3734C30.051 21.6724 29.7595 22.9762 29.0549 24H3.46597L0.117171 9.49487C-0.449569 7.04004 1.09521 4.56793 3.55001 4.00118Z"
        fill="#EF7E20"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.98338 18.0958H7.59712V15.074H4.76907V18.0958H3.38281V11.1179H4.76907V13.8425H7.59712V11.1179H8.98338V18.0958Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7273 11.3816C11.7273 11.4964 11.7057 11.604 11.6591 11.7044C11.6143 11.8048 11.5533 11.8927 11.4762 11.968C11.3991 12.0433 11.3076 12.1025 11.2019 12.1455C11.0978 12.1904 10.9866 12.2119 10.8719 12.2119C10.7589 12.2119 10.653 12.1904 10.5526 12.1455C10.454 12.1025 10.3661 12.0433 10.2908 11.968C10.2155 11.8927 10.1564 11.8048 10.1115 11.7044C10.0685 11.604 10.0469 11.4964 10.0469 11.3816C10.0469 11.265 10.0685 11.1574 10.1115 11.0552C10.1564 10.9547 10.2155 10.865 10.2908 10.7897C10.3661 10.7145 10.454 10.6553 10.5526 10.6122C10.653 10.5674 10.7589 10.5459 10.8719 10.5459C10.9866 10.5459 11.0978 10.5674 11.2019 10.6122C11.3076 10.6553 11.3991 10.7145 11.4762 10.7897C11.5533 10.865 11.6143 10.9547 11.6591 11.0552C11.7057 11.1574 11.7273 11.265 11.7273 11.3816Z"
        fill="#FFEC00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8669 19.7848V18.1719L12.797 18.1726C12.4366 18.1726 12.1012 18.1133 11.7892 17.9932C11.7132 17.9644 11.6398 17.9321 11.5686 17.8962C10.7404 17.47 10.3236 16.5981 10.2344 15.3714V15.3548V13.0474H11.5686C11.5686 13.6986 11.6075 14.8089 11.5686 15.6762C11.5686 15.8754 11.6297 16.1482 11.6996 16.3344C11.7694 16.5191 11.8627 16.6715 11.9775 16.7899C12.094 16.91 12.2304 16.9979 12.3881 17.0553C12.5332 17.1097 12.6918 17.1383 12.8669 17.1426V13.0474H13.6865C13.7708 13.0474 13.843 13.0666 13.8999 13.1048C13.9576 13.1435 13.9933 13.2039 14.0128 13.2841L14.1241 13.7415C14.2281 13.6249 14.3394 13.5173 14.4541 13.4205C14.5706 13.322 14.6946 13.2379 14.8306 13.1676C14.9652 13.0981 15.1103 13.043 15.2664 13.0044C15.4222 12.966 15.591 12.947 15.7722 12.947C16.0501 12.947 16.3027 13.007 16.5342 13.1263C16.7652 13.2453 16.9659 13.4174 17.1333 13.6428C17.6056 14.2795 17.6641 14.6372 17.6641 15.4449C17.6641 15.834 17.6121 16.1945 17.5063 16.5281C17.4023 16.8598 17.2535 17.1485 17.0597 17.3925C16.8679 17.6364 16.6347 17.8265 16.3639 17.9646C16.0931 18.1026 15.7883 18.1726 15.4529 18.1726C15.1696 18.1726 14.9293 18.1295 14.7321 18.0434C14.5365 17.9573 14.359 17.8407 14.2012 17.6937V19.7848H12.8669ZM15.3166 13.8327C15.0674 13.8327 14.8575 13.8829 14.6854 13.9815C14.5132 14.0819 14.3518 14.2272 14.2012 14.4155V16.7271C14.3357 16.8885 14.4792 17.0015 14.6352 17.0661C14.7912 17.1306 14.9579 17.1629 15.1373 17.1629C15.3094 17.1629 15.4655 17.1288 15.6072 17.0606C15.7488 16.9925 15.8708 16.8885 15.973 16.7486C16.0734 16.6087 16.1524 16.4312 16.2062 16.2161C16.2599 15.999 16.2886 15.7426 16.2886 15.4449C16.2886 15.1507 16.2653 14.9016 16.2205 14.6971C16.1738 14.4926 16.1093 14.3258 16.025 14.1985C15.9407 14.0694 15.8402 13.9779 15.7202 13.9187C15.6018 13.8614 15.4673 13.8327 15.3166 13.8327Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8294 12.9663C21.1594 12.9663 21.4629 13.0195 21.7386 13.1259C22.0149 13.2325 22.253 13.3884 22.4524 13.5887C22.6525 13.7898 22.8086 13.8686 22.9204 14.1622C23.0324 14.4564 23.089 14.7916 23.089 15.1701C23.089 15.2723 23.0836 15.3548 23.0729 15.4193C23.0622 15.4839 23.046 15.5359 23.0226 15.5753C23.0011 15.6131 22.9689 15.6399 22.9294 15.656C22.8882 15.6722 22.838 15.6793 22.7788 15.6793H19.6584C19.6799 15.9322 19.7248 16.151 19.7947 16.3375C19.8646 16.5222 19.9578 16.6747 20.0727 16.793C20.1892 16.9131 20.3256 17.001 20.4832 17.0584C20.6411 17.1177 20.8151 17.1463 21.0087 17.1463C21.2007 17.1463 21.3675 17.123 21.5091 17.0746C21.6526 17.0279 21.7763 16.9759 21.8838 16.9222C21.9898 16.8683 22.0848 16.8181 22.1691 16.7697C22.2534 16.7231 22.3359 16.6997 22.4165 16.6997C22.5152 16.6997 22.5941 16.7392 22.6586 16.8199L23.0425 17.3131C22.8971 17.4817 22.7393 17.6216 22.5636 17.731C22.3878 17.8421 22.2067 17.93 22.0202 17.9946C21.8319 18.0592 21.6419 18.1058 21.4499 18.1344C21.2562 18.1614 21.0715 18.1757 20.8922 18.1757C18.5028 18.1757 17.8195 15.4275 18.9984 13.7249C19.1789 13.4642 19.4756 13.3069 19.7839 13.1708C20.0922 13.0346 20.4403 12.9663 20.8294 12.9663ZM20.8563 13.7462C20.5156 13.7462 20.2502 13.8447 20.0565 14.0439C19.8646 14.2411 19.7409 14.5208 19.6834 14.8813H21.8911C21.8911 14.7307 21.8713 14.5872 21.8301 14.4509C21.7906 14.3147 21.7279 14.1926 21.6419 14.0886C21.5558 13.9828 21.4481 13.9004 21.319 13.8376C21.188 13.7767 21.0339 13.7462 20.8563 13.7462Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9922 18.0936V13.0474H24.7759C24.9121 13.0474 25.0071 13.0733 25.061 13.121C25.1158 13.1694 25.1548 13.2558 25.1703 13.3774L25.2385 13.9082C25.3964 13.6106 25.58 13.3758 25.789 13.2053C25.9989 13.0339 26.2427 12.947 26.5189 12.947C26.7467 12.947 26.935 13.0008 27.0821 13.1102L26.9817 13.9385C26.9743 14.0004 26.9536 14.0454 26.9188 14.0712C26.8844 14.0967 26.8364 14.1106 26.7772 14.1106C26.7252 14.1106 26.6499 14.1034 26.5512 14.0873C26.4527 14.0712 26.3629 14.064 26.2787 14.064C26.1549 14.064 26.0455 14.0819 25.9505 14.116C25.8536 14.1519 25.7676 14.2039 25.6922 14.272C25.6169 14.3402 25.5487 14.4227 25.4896 14.5213C25.4304 14.6181 25.3765 14.7312 25.3264 14.8567V18.0936H23.9922Z"
        fill="white"
      />
    </svg>
  )
}
