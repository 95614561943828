type AmexIconProps = {
  className?: string
}

export function AmexIcon({ className }: AmexIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...{ className }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H24V24H0V0Z"
        fill="#016FD0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.69141 20.2021V11.6025L23.9974 11.6163V13.9917L22.2283 15.8823L23.9974 17.7901V20.216H21.1729L19.6718 18.5596L18.1814 20.2224L8.69141 20.2021Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.71484 19.2593V12.5489H15.4047V14.0946H11.5571V15.1439H15.313V16.6642H11.5571V17.6949H15.4047V19.2593H9.71484Z"
        fill="#016FD0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.375 19.2593L18.5234 15.9002L15.375 12.549H17.8118L19.7357 14.6758L21.665 12.549H23.9971V12.6018L20.9158 15.9002L23.9971 19.1641V19.2593H21.6415L19.6835 17.1111L17.7457 19.2593H15.375Z"
        fill="#016FD0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.40652 3.97047H13.096L14.3919 6.913V3.97047H18.9468L19.7323 6.17513L20.5204 3.97047H23.9985V12.5701H5.61719L9.40652 3.97047Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1064 4.90433L7.12891 11.6091H9.17104L9.73277 10.2665H12.7765L13.3378 11.6091H15.4308L12.4657 4.90433H10.1064ZM10.3622 8.7618L11.2552 6.6274L12.1474 8.7618H10.3622Z"
        fill="#016FD0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4023 11.6083V4.90362L18.2729 4.91349L19.7499 9.03535L21.2366 4.90362H23.9993V11.6084L22.2215 11.624V7.01842L20.5434 11.6084H18.9207L17.2078 7.00282V11.6084L15.4023 11.6083Z"
        fill="#016FD0"
      />
    </svg>
  )
}
