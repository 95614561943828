import { LinksContainer } from './styles'
import { useRouter } from 'next/router'
import NavLink from './NavLink'
import { useAmplitude } from '@/lib/services/amplitude/useAmplitude'
import {
  AmplitudeEventsNameJobRole,
  AmplitudeEventsNameCandidates,
} from '@/lib/models/enums'
import { useBreadCrumb } from '@/lib/context/BreadCrumbContext'
import { Routes } from '@/lib/models/enums/Routes.enum'
import { getAuthToken } from '@/lib/storage'
import jwt_decode from 'jwt-decode'
import { JwtDecode } from '@/lib/models/types'

const baseNavLinks = [
  { href: Routes.JOBROLES, text: 'Vagas' },
  { href: Routes.CANDIDATES, text: 'Candidatos' },
  { href: Routes.DASHBOARD, text: 'Dashboard' },
]

const CSNavLinks = [{ href: Routes.USERS, text: 'Usuários' }]
const thirdPartyCSNavLinks = [{ href: Routes.USERS, text: 'Usuários' }]

const oiThirdPartyOperatorLinks = [
  { href: Routes.OI_REGISTER_USER, text: 'Cadastro' },
]

const marketingLinks = [{ href: Routes.NOTIFICATIONS, text: 'Notificações' }]

const vojoNavLinks = [{ href: Routes.BENEFITS, text: 'Benefícios' }]

const operatorNavLinks = [
  { href: Routes.COMPANIES, text: 'Empresas' },
  ...baseNavLinks,
  { href: Routes.ACCESSES, text: 'Acessos' },
]

const navLinks = {
  OPERATOR: operatorNavLinks,
  MANAGER: baseNavLinks,
  CUSTOMER_SUCCESS: CSNavLinks,
  THIRD_PARTY_CUSTOMER_SUCCESS: thirdPartyCSNavLinks,
  VOJO: vojoNavLinks,
  USER: [],
  OI_THIRD_PARTY_OPERATOR: oiThirdPartyOperatorLinks,
  MARKETING: marketingLinks,
}

function isKeyOfNavLinks(key: string): key is keyof typeof navLinks {
  return key in navLinks
}

function getNavLink(role: string): { href: string; text: string }[] {
  if (isKeyOfNavLinks(role)) return navLinks[role]
  return []
}

const createNavLinks = (token?: string) => {
  if (!token) return baseNavLinks
  const { permissions }: JwtDecode = jwt_decode(token)

  return permissions.map(role => getNavLink(role)).flat()
}

export const NavLinks: React.FC = () => {
  const { clearBreadCrumbHistory } = useBreadCrumb()
  const { asPath } = useRouter()
  const { dispatchEvent } = useAmplitude()

  const token = getAuthToken()

  const pathName =
    typeof window !== 'undefined' ? window.location.pathname : null
  const navLinks = createNavLinks(token)

  const event = pathName?.includes('candidates')
    ? AmplitudeEventsNameCandidates.ClickMainMenu
    : AmplitudeEventsNameJobRole.ClickMainMenu

  const eventProperties = undefined

  const onClickNavLink = () => {
    dispatchEvent({ event, eventProperties })
    clearBreadCrumbHistory()
  }

  if (typeof window === 'undefined') return <LinksContainer />

  return (
    <LinksContainer>
      {navLinks.map(({ href, text }) => (
        <NavLink
          onClick={onClickNavLink}
          key={href}
          active={asPath === href}
          {...{ href, text }}
        />
      ))}
    </LinksContainer>
  )
}
