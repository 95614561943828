import { FC } from 'react'
import { IconsProps } from '.'

const CancelFilled: FC<IconsProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{ height, width }}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.576 0 0 3.576 0 8C0 12.424 3.576 16 8 16C12.424 16 16 12.424 16 8C16 3.576 12.424 0 8 0ZM12 10.872L10.872 12L8 9.128L5.128 12L4 10.872L6.872 8L4 5.128L5.128 4L8 6.872L10.872 4L12 5.128L9.128 8L12 10.872Z"
        fill={color}
      />
    </svg>
  )
}

export default CancelFilled
