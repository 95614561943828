import styled, { css } from 'styled-components'
import StylableNextLink from '@/components/StylableNextLink'

type Props = {
  active: boolean
}

export default styled(StylableNextLink)<Props>(
  ({ active }) =>
    css`
      font-size: 1.125em;
      line-height: 1.25em;
      color: ${active
        ? ({ theme }) => theme.edukRed
        : ({ theme }) => theme.darkGrey};
      font-weight: ${active ? 500 : 'normal'};
      margin: 0 2vw;
      &:hover {
        font-weight: 600;
        color: ${({ theme }) => theme.edukRed};
        opacity: 50%;
      }
    `
)
