type FailedIconProps = {
  className?: string
}
export function FailedIcon({ className }: FailedIconProps) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="24" cy="24" r="23" stroke="#89039E" strokeWidth="2" />
      <path
        d="M26.212 18.82C26.212 20.836 26.14 22.672 25.996 24.328C25.852 25.96 25.672 27.592 25.456 29.224H23.368C23.152 27.592 22.972 25.96 22.828 24.328C22.684 22.672 22.612 20.836 22.612 18.82V12.052H26.212V18.82ZM26.824 35.056C26.824 35.704 26.608 36.268 26.176 36.748C25.744 37.228 25.156 37.468 24.412 37.468C23.668 37.468 23.08 37.228 22.648 36.748C22.216 36.268 22 35.704 22 35.056C22 34.408 22.216 33.844 22.648 33.364C23.08 32.884 23.668 32.644 24.412 32.644C25.156 32.644 25.744 32.884 26.176 33.364C26.608 33.844 26.824 34.408 26.824 35.056Z"
        fill="#89039E"
      />
    </svg>
  )
}
export default FailedIcon
