import { FC } from 'react'
import { IconsProps } from '.'

const Hamburger: FC<IconsProps> = ({ width = '18', height = '12', color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{ height, width }}
      viewBox="0 0 18 12"
    >
      <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" fill={color} />
    </svg>
  )
}

export default Hamburger
