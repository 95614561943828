import Link, { LinkProps } from 'next/link'
import React from 'react'

export type StylableNextLinkProps = Omit<LinkProps, 'passHref'> & {
  className?: string
  isTarget?: boolean
  onClick?: () => void
}

const StylableNextLink: React.FC<StylableNextLinkProps> = ({
  href,
  className,
  children,
  isTarget,
  onClick,
  ...rest
}) => {
  return (
    <Link href={href} passHref {...rest}>
      <a {...{ onClick, className }} target={isTarget ? '_blank' : ''}>
        {children}
      </a>
    </Link>
  )
}

export default StylableNextLink
