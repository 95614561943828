import { FC } from 'react'
import { IconsProps } from '.'

const File: FC<IconsProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{ height, width }}
      viewBox="0 0 12 14"
      fill="none"
    >
      <path
        d="M3.33329 9.66665H8.66663V11H3.33329V9.66665ZM3.33329 6.99998H8.66663V8.33331H3.33329V6.99998ZM7.33329 0.333313H1.99996C1.26663 0.333313 0.666626 0.933313 0.666626 1.66665V12.3333C0.666626 13.0666 1.25996 13.6666 1.99329 13.6666H9.99996C10.7333 13.6666 11.3333 13.0666 11.3333 12.3333V4.33331L7.33329 0.333313ZM9.99996 12.3333H1.99996V1.66665H6.66663V4.99998H9.99996V12.3333Z"
        fill={color}
      />
    </svg>
  )
}

export default File
