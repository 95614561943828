type AwaitingPropsIcon = {
  className?: string
}
export function AwaitingIcon({ className }: AwaitingPropsIcon) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="24"
        cy="24"
        r="23"
        stroke="#FFD028"
        fill="#FFD028"
        strokeWidth="2"
      />
      <path
        d="M24.7086 14C19.0229 14 14.4286 18.4778 14.4286 24H11L15.4514 28.3278L15.5314 28.4889L20.1429 24H16.7143C16.7143 19.7056 20.2971 16.2222 24.7143 16.2222C29.1314 16.2222 32.7143 19.7056 32.7143 24C32.7143 28.2944 29.1314 31.7778 24.7143 31.7778C22.5029 31.7778 20.5086 30.9 19.0629 29.4944L17.4457 31.0667C19.3029 32.8778 21.8686 34 24.7086 34C30.3943 34 35 29.5222 35 24C35 18.4778 30.3943 14 24.7086 14ZM23.5714 19.5556V25.1111L28.4629 27.9333L29.2857 26.5833L25.2857 24.2778V19.5556H23.5714Z"
        fill="white"
      />
    </svg>
  )
}
export default AwaitingIcon
