import { FC } from 'react'
import { IconsProps } from '.'

const ArrowRigth: FC<IconsProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{ height, width }}
      viewBox="0 0 24 24"
    >
      <path
        d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
        fill={color}
      />
      <path d="M0 0h24v24H0V0z" fill="none" />
    </svg>
  )
}

export default ArrowRigth
