type MasterCardIconProps = {
  className?: string
}

export function MasterCardIcon({ className }: MasterCardIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="24"
      viewBox="0 0 39 24"
      fill="none"
      {...{ className }}
    >
      <path
        d="M25.1886 2.56519H13.6367V21.4329H25.1886V2.56519Z"
        fill="#FF5F00"
      />
      <path
        d="M14.8298 11.9998C14.8283 10.183 15.2402 8.3897 16.0341 6.75558C16.8281 5.12145 17.9834 3.68934 19.4125 2.56762C17.6425 1.17653 15.5167 0.311495 13.2783 0.0713781C11.0398 -0.168739 8.77897 0.225752 6.75412 1.20976C4.72927 2.19377 3.0221 3.72761 1.82774 5.63596C0.633377 7.54431 0 9.75018 0 12.0015C0 14.2528 0.633377 16.4586 1.82774 18.367C3.0221 20.2753 4.72927 21.8092 6.75412 22.7932C8.77897 23.7772 11.0398 24.1717 13.2783 23.9316C15.5167 23.6915 17.6425 22.8264 19.4125 21.4353C17.9829 20.3133 16.8274 18.8806 16.0334 17.2459C15.2394 15.6111 14.8278 13.8172 14.8298 11.9998Z"
        fill="#EB001B"
      />
      <path
        d="M37.6792 19.4351V19.0485H37.8458V18.9685H37.4492V19.0485H37.6059V19.4351H37.6792ZM38.4491 19.4351V18.9685H38.3291L38.1891 19.3018L38.0491 18.9685H37.9292V19.4351H38.0158V19.0818L38.1458 19.3851H38.2358L38.3658 19.0818V19.4351H38.4491Z"
        fill="#F79E1B"
      />
      <path
        d="M38.8284 12.0016C38.8283 14.2531 38.1948 16.4591 37.0002 18.3675C35.8056 20.2759 34.0982 21.8097 32.0731 22.7936C30.048 23.7774 27.7869 24.1717 25.5483 23.9312C23.3098 23.6908 21.184 22.8253 19.4141 21.4338C20.8427 20.3111 21.9976 18.8786 22.7918 17.2443C23.5859 15.6101 23.9986 13.8169 23.9986 11.9999C23.9986 10.183 23.5859 8.38973 22.7918 6.75551C21.9976 5.1213 20.8427 3.6888 19.4141 2.56608C21.184 1.17455 23.3098 0.309097 25.5483 0.0686355C27.7869 -0.171826 30.048 0.22241 32.0731 1.20628C34.0982 2.19015 35.8056 3.72396 37.0002 5.63237C38.1948 7.54079 38.8283 9.7468 38.8284 11.9983V12.0016Z"
        fill="#F79E1B"
      />
    </svg>
  )
}
