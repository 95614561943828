import React from 'react'
import { Grow, ClickAwayListener, Divider } from '@material-ui/core'
import { Container, StyledMenuList, StyledPaper } from './styles'
import MenuItem, { MenuItemProps } from './MenuItem'

export type PopperProps = {
  menuItems: MenuItemProps[]
  anchorRef: React.RefObject<HTMLButtonElement>
  open: boolean
  closeMenu: () => void
}

const Popper: React.FC<PopperProps> = ({
  menuItems,
  anchorRef,
  open,
  closeMenu,
}) => {
  const onClickAway = () => closeMenu()

  const displayMenuItems = menuItems?.map((item, index) => [
    <MenuItem {...item} key={index} />,
    <Divider key={`divider${index}`} />,
  ])

  return (
    <Container
      open={open}
      anchorEl={anchorRef?.current}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <StyledPaper>
            <ClickAwayListener onClickAway={onClickAway}>
              <StyledMenuList autoFocusItem={open} id="menu-list-grow">
                {displayMenuItems}
              </StyledMenuList>
            </ClickAwayListener>
          </StyledPaper>
        </Grow>
      )}
    </Container>
  )
}

export default Popper
