import { FC } from 'react'
import { IconsProps } from '.'

const ArrowLeft: FC<IconsProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{ height, width }}
      viewBox="0 0 24 24"
    >
      <path
        d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
        fill={color}
      />
      <path d="M0 0h24v24H0V0z" fill="none" />
    </svg>
  )
}

export default ArrowLeft
