import { FC } from 'react'
import { IconsProps } from '.'

const Search: FC<IconsProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{ height, width }}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94623 10.0629C3.66835 10.0629 1.82959 8.22413 1.82959 5.94626C1.82959 3.66838 3.66835 1.82962 5.94623 1.82962C8.2241 1.82962 10.0629 3.66838 10.0629 5.94626C10.0629 8.22413 8.2241 10.0629 5.94623 10.0629Z"
        fill={color}
      />
    </svg>
  )
}

export default Search
