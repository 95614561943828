import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

export const Content = styled.main`
  transition: 0.2s all;
  height: fit-content;
  display: grid;
  align-content: baseline;

  grid-template-areas:
    'breadCrumbs empty'
    'title empty'
    'search-box actions'
    'total-elements amount-info'
    'table table'
    'pagination pagination';

  margin: 1em clamp(2em, 7.5%, 8em);

  & > p {
    display: flex;
    justify-content: space-between;
  }
`
