import { StyledButton } from './styles'
import { MouseEventHandler } from 'react'
import StylableNextLink from '../StylableNextLink'
import { ReactNode } from 'react'

export type MenuItemProps = { element: ReactNode; isTarget?: boolean } & (
  | { href: string; onClick?: never }
  | { href?: never; onClick: MouseEventHandler }
)
const MenuItem: React.FC<MenuItemProps> = ({
  element,
  onClick,
  href,
  isTarget,
}) => {
  if (onClick) return <StyledButton {...{ onClick }}>{element}</StyledButton>

  return (
    <StylableNextLink href={href as string} isTarget={isTarget as boolean}>
      <StyledButton>{element}</StyledButton>
    </StylableNextLink>
  )
}

export default MenuItem
