function NotFound({
  width = '207',
  height = '180',
  className,
}: {
  width?: string
  height?: string
  className?: string
}): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{ width, height, className }}
      viewBox="0 0 207 180"
      fill="none"
    >
      <path
        d="M165.01 145.708C159.784 147.045 148.045 147.372 142.183 147.029L63.1582 140.697C43.0545 139.495 26.816 133.123 18.8514 114.893C10.8868 96.6625 13.9569 76.4273 26.7321 61.0788L51.9279 35.6093C75.0525 19.8506 99.7515 18.1565 121.952 35.6102L168.828 66.1401C187.142 80.4936 204.755 95.982 196.266 117.604C190.13 133.915 180.88 141.647 165.01 145.708Z"
        fill="#F4F4F4"
      />
      <path
        d="M136.014 82.6606C135.754 82.6606 135.233 82.6606 134.973 82.4005L103.24 64.1931L71.5075 82.4005C70.4671 82.9207 69.1665 82.6606 68.6463 81.6202C68.1261 80.5798 68.3862 79.2792 69.4267 78.759L102.2 59.7713C102.72 59.5112 103.761 59.5112 104.281 59.7713L137.054 78.759C138.095 79.2792 138.355 80.5798 137.835 81.6202C137.314 82.1404 136.794 82.6606 136.014 82.6606Z"
        fill="#4F4F4F"
      />
      <path
        d="M103.24 143.265C102.98 143.265 102.46 143.265 102.2 143.005L69.4267 124.278C68.9064 124.017 68.3862 123.237 68.3862 122.457V99.3074C68.3862 98.267 69.4267 97.2266 70.4671 97.2266C71.5075 97.2266 72.5479 98.267 72.5479 99.3074V121.156L103.24 138.844L133.933 121.156V99.3074C133.933 98.267 134.973 97.2266 136.014 97.2266C137.054 97.2266 138.095 98.267 138.095 99.3074V122.457C138.095 123.237 137.574 124.017 137.054 124.278L104.281 143.265C104.021 143.265 103.501 143.265 103.24 143.265Z"
        fill="#4F4F4F"
      />
      <path
        d="M103.24 143.265C102.2 143.265 101.16 142.225 101.16 141.184V100.608L69.4267 82.4002C68.3862 81.88 68.1261 80.5795 68.6463 79.5391C69.1665 78.4987 70.4671 78.2386 71.5075 78.7588L104.281 97.7465C104.801 98.0066 105.321 98.7869 105.321 99.5672V141.444C105.321 142.485 104.281 143.265 103.24 143.265Z"
        fill="#4F4F4F"
      />
      <path
        d="M103.241 101.388C102.46 101.388 101.94 101.127 101.42 100.347C100.9 99.3067 101.16 98.0061 102.2 97.4859L134.973 78.4982C136.014 77.978 137.314 78.2381 137.835 79.2785C138.355 80.3189 138.095 81.6194 137.054 82.1397L104.281 101.127C104.021 101.388 103.501 101.388 103.241 101.388Z"
        fill="#4F4F4F"
      />
      <path
        d="M136.014 82.6603C135.234 82.6603 134.713 82.4002 134.193 81.6199C133.673 80.5795 133.933 79.279 134.973 78.7588L148.239 71.2157L119.627 54.829L104.281 63.6726C103.241 64.1928 101.94 63.9327 101.42 62.8923C100.9 61.8519 101.16 60.5513 102.2 60.0311L118.587 50.6673C119.107 50.4072 120.147 50.4072 120.668 50.6673L153.441 69.655C153.961 69.9152 154.481 70.6955 154.481 71.4758C154.481 72.2561 153.961 73.0364 153.441 73.2965L137.054 82.1401C136.794 82.4002 136.274 82.6603 136.014 82.6603Z"
        fill="#4F4F4F"
      />
      <path
        d="M70.4675 82.6605C70.2074 82.6605 69.6872 82.6605 69.4271 82.4004L53.0404 73.0366C52.5202 72.7764 52 71.9961 52 71.2158C52 70.4355 52.5202 69.6552 53.0404 69.3951L85.8138 50.4073C86.334 50.1472 87.3744 50.1472 87.8946 50.4073L104.281 59.7711C105.322 60.2914 105.582 61.5919 105.062 62.6323C104.541 63.6727 103.241 63.9328 102.2 63.4126L86.8542 54.569L58.2425 70.9557L71.5079 78.4988C72.5484 79.019 72.8085 80.3195 72.2883 81.3599C72.0282 82.1403 71.2478 82.6605 70.4675 82.6605Z"
        fill="#4F4F4F"
      />
      <path
        d="M86.8542 110.751C86.5941 110.751 86.0739 110.751 85.8138 110.491L53.0404 91.5035C52.5202 91.2434 52 90.4631 52 89.6827C52 88.9024 52.5202 88.1221 53.0404 87.862L69.4271 78.4982C70.4675 77.978 71.7681 78.2381 72.2883 79.2785C72.8085 80.3189 72.5484 81.6194 71.5079 82.1397L58.2425 89.6827L86.8542 106.069L102.2 97.2258C103.241 96.7056 104.541 96.9657 105.062 98.0061C105.582 99.0466 105.322 100.347 104.281 100.867L87.8946 110.231C87.6345 110.751 87.1143 110.751 86.8542 110.751Z"
        fill="#4F4F4F"
      />
      <path
        d="M119.627 110.752C119.367 110.752 118.847 110.752 118.587 110.492L102.2 101.128C101.16 100.608 100.9 99.3071 101.42 98.2667C101.94 97.2263 103.241 96.9662 104.281 97.4864L119.627 106.33L148.239 89.9433L134.973 82.4002C133.933 81.88 133.673 80.5795 134.193 79.5391C134.713 78.4987 136.014 78.2386 137.054 78.7588L153.441 88.1226C153.961 88.3827 154.481 89.163 154.481 89.9433C154.481 90.7236 153.961 91.504 153.441 91.7641L120.668 110.752C120.408 110.752 119.887 110.752 119.627 110.752Z"
        fill="#4F4F4F"
      />
      <path
        d="M91.8331 39.6146L85.688 29.958L82.7254 31.8433L88.8705 41.4999L91.8331 39.6146Z"
        fill="#4F4F4F"
      />
      <path
        d="M123.437 31.8403L120.474 29.9554L114.33 39.612L117.293 41.497L123.437 31.8403Z"
        fill="#4F4F4F"
      />
      <path
        d="M104.397 28.2657H100.886V39.6779H104.397V28.2657Z"
        fill="#4F4F4F"
      />
      <circle cx="156.5" cy="43.5001" r="2" fill="#FF0033" stroke="#FF0033" />
      <circle cx="161" cy="128" r="3" stroke="#FF0033" strokeWidth="2" />
      <circle cx="27" cy="62.0001" r="3" stroke="#FF0033" strokeWidth="2" />
      <path
        d="M178 95.0001H182"
        stroke="#FF0033"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M180 97.0001L180 93.0001"
        stroke="#FF0033"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 114C48 113.448 48.4477 113 49 113L55 113C55.5523 113 56 113.448 56 114C56 114.552 55.5523 115 55 115L49 115C48.4477 115 48 114.552 48 114Z"
        fill="#FF0033"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52 118C51.4477 118 51 117.552 51 117V111C51 110.448 51.4477 110 52 110C52.5523 110 53 110.448 53 111V117C53 117.552 52.5523 118 52 118Z"
        fill="#FF0033"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 114C48 113.448 48.4477 113 49 113L55 113C55.5523 113 56 113.448 56 114C56 114.552 55.5523 115 55 115L49 115C48.4477 115 48 114.552 48 114Z"
        stroke="#FF0033"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52 118C51.4477 118 51 117.552 51 117V111C51 110.448 51.4477 110 52 110C52.5523 110 53 110.448 53 111V117C53 117.552 52.5523 118 52 118Z"
        stroke="#FF0033"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default NotFound
