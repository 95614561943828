/* eslint-disable @typescript-eslint/no-explicit-any */
import { capitalize } from '../formatters/capitalize'

type FormattedObject = {
  [key: string]: string | number
}

export function flattenAndFormatObject(obj: any, prefix = ''): FormattedObject {
  const result: FormattedObject = {}

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      const newKey = prefix ? `${prefix}${capitalize(key)}` : key

      const valueType = getType(value)

      switch (valueType) {
        case 'array':
          const arr = value as any[]
          arr.forEach((item, index) => {
            const arrayKey = `${newKey}_${index + 1}`
            if (isObject(item)) {
              Object.assign(
                result,
                flattenAndFormatObject(item, `${arrayKey}_`)
              )
            } else {
              result[arrayKey] = formatValue(item)
            }
          })
          break

        case 'object':
          Object.assign(result, flattenAndFormatObject(value, newKey))
          break

        case 'date':
          result[newKey] = value.toISOString()
          break

        default:
          result[newKey] = formatValue(value)
          break
      }
    }
  }

  return result
}

function isObject(value: unknown) {
  return typeof value == 'object'
}

function getType(value: any) {
  const isArray = Array.isArray(value)
  const isDate = value instanceof Date

  if (isNullish(value)) return 'primitive'
  if (isDate) return 'date'
  if (isArray) return 'array'
  if (!isArray && isObject(value)) return 'object'

  return 'primitive'
}

function isAcceptedType(value: any) {
  const acceptedTypes = ['string', 'number']
  return acceptedTypes.includes(typeof value)
}

function isNullish(value: any) {
  return [null, undefined].includes(value)
}

function formatValue(value: any): string | number {
  if (isNullish(value)) return ''
  return isAcceptedType(value) ? value : `${value}`
}
