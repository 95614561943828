import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

export const Content = styled.main`
  transition: 0.2s all;
  height: fit-content;
  display: grid;
  align-content: baseline;

  grid-template-areas:
    'breadCrumbs'
    'title'
    'userData'
    'plan'
    'history'
    'attachPlanSection'
    'earlyRenewal'
    'changeEmail'
    'changePassword'
    'disablePlan'
    'disableSubscription';

  margin: 1em clamp(2em, 7.5%, 8em);

  > section {
    box-shadow: 0px 0px 4px 2px rgba(107, 107, 107, 0.2);
    border-radius: 1rem;
    padding: 2rem;
    margin-bottom: 2.5rem;
    h1 {
      color: ${({ theme }) => theme.lateBlack};
    }
  }

  & > p {
    display: flex;
    justify-content: space-between;
  }
`
