import { Logo, Container } from './styles'
import Dropdown from '../Dropdown'
import { EdukIncoming } from '@/components/Logo/EdukIncoming'
import { MenuItemProps } from '../Popper/MenuItem'
import { useAuth } from '@/lib/context/AuthContext'
import Icon from '../Icon'

import { useCompany } from '@/lib/api/queries/useCompany'
import { NavLinks } from './NavLinks'

const NavBar: React.FC = () => {
  const { logout, user } = useAuth()
  const { company } = useCompany(user?.companyId)

  const menuItems: MenuItemProps[] = [
    { onClick: logout, element: 'Sair' },
    {
      href: 'http://vojo.com.br/faq-painel/',
      element: 'FAQ',
      isTarget: true,
    },
  ]

  const companyLogo = company?.logo ? (
    <Logo src={company.logo} alt="Logo da empresa" width={66} height={32} />
  ) : (
    <EdukIncoming />
  )

  return (
    <Container data-testid="navbar">
      <EdukIncoming />
      <NavLinks />
      <Dropdown
        label={companyLogo}
        menuItems={menuItems}
        icon={<Icon icon="ArrowDown" color="primary" />}
      />
    </Container>
  )
}

export default NavBar
