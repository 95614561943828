import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

export const Content = styled.main`
  transition: 0.2s all;
  height: fit-content;
  display: grid;
  align-content: baseline;

  grid-template-areas:
    'breadCrumbs empty'
    'title empty'
    'card card';

  margin: 1em clamp(2em, 7.5%, 8em);

  & > p {
    display: flex;
    justify-content: space-between;
  }

  .mainContent {
    border: 1px solid ${({ theme }) => theme.mediumGrey};
    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    grid-area: card;
    height: 72vh;
    display: grid;
    grid-template-areas:
      'sideMenu content'
      'sideMenu content'
      'sideMenu actions';
    grid-template-rows: 1fr 1fr 4.25em;
    grid-template-columns: 11.5em 1fr;
  }
`
