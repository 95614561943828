import { FC } from 'react'
import { IconsProps } from '.'

const ArrowRigth: FC<IconsProps> = ({ width, height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{ height, width }}
      viewBox="0 0 24 24"
    >
      <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" fill={color} />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
}

export default ArrowRigth
