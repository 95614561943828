import NavBar from '@/components/NavBar'
import { Container, Content } from './style'

export const UserDetailLayout: React.FC = ({ children }) => (
  <Container>
    <NavBar />
    <Content>{children}</Content>
  </Container>
)

export default UserDetailLayout
