import theme from '@/styles/theme'
import { FC } from 'react'
import { IconsProps } from '.'

const Copy: FC<Partial<IconsProps>> = ({
  width = '13',
  height = '16',
  color = theme.mediumGrey,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...{ height, width, className }}
      viewBox="0 0 13 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.66671 0.666992H1.66671C0.933374 0.666992 0.333374 1.26699 0.333374 2.00033V11.3337H1.66671V2.00033H9.66671V0.666992ZM11.6667 3.33366H4.33337C3.60004 3.33366 3.00004 3.93366 3.00004 4.66699V14.0003C3.00004 14.7337 3.60004 15.3337 4.33337 15.3337H11.6667C12.4 15.3337 13 14.7337 13 14.0003V4.66699C13 3.93366 12.4 3.33366 11.6667 3.33366ZM11.6667 14.0003H4.33337V4.66699H11.6667V14.0003Z"
        fill={color}
      />
    </svg>
  )
}

export default Copy
