import { FC } from 'react'
import Icons, { IconType } from './Icons'
import theme from '@/styles/theme'
export type IconProps = {
  height?: string
  color?: string
  icon: IconType
  width?: string
}

const Icon: FC<IconProps> = ({
  width = '24px',
  height = '24px',
  color = `${theme.darkGrey}`,
  icon,
}) => {
  const IconComponent = Icons[icon]

  const themeColor = {
    primary: theme.edukRed,
    disabled: theme.mediumGrey,
    error: theme.red,
  }[color]

  return (
    <IconComponent
      {...{
        width,
        height,
        color: themeColor || color,
        icon,
      }}
    />
  )
}

export default Icon
