import NavBar from '@/components/NavBar'
import { Container, Content } from './styles'

export const SimpleFormLayout: React.FC = ({ children }) => (
  <Container>
    <NavBar />
    <Content>{children}</Content>
  </Container>
)

export default SimpleFormLayout
