export enum AmplitudeEventsNameJobRole {
  ChangeStatus = 'alteracao_status_vaga',
  ClickMainMenu = 'cliques_menu_principal_vaga',
  ClickSideMenu = 'cliques_menu_lateral_vaga',
}

export enum AmplitudeEventsNameCandidates {
  Filter = 'filtragem_candidato',
  ClickMainMenu = 'cliques_menu_principal_candidato',
  ClickSideMenu = 'cliques_menu_lateral_candidato',
  ClickImportCsv = 'cliques_importar_csv',
  ExportCsv = 'csv_exportado_candidato',
  ImportCsvSuccess = 'csv_importado_candidato_sucesso',
  ImportCsvChangeStatus = 'csv_importado_candidato_alteracao_status',
  ImportCsvError = 'csv_importado_erro_candidato',
  ChangeStatusInLargeScale = 'alteracao_status_massa_candidato',
  ChangeStatusOneAOne = 'alteracao_status_1a1_candidato',
}

export type AmplitudeEventName =
  | AmplitudeEventsNameJobRole
  | AmplitudeEventsNameCandidates
