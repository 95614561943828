import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

export const Content = styled.main`
  transition: 0.2s all;
  height: fit-content;
  display: grid;
  align-content: baseline;

  grid-template-areas:
    'breadCrumbs empty'
    'title empty'
    'card card';

  margin: 1em clamp(2em, 7.5%, 8em);

  & > p {
    display: flex;
    justify-content: space-between;
  }

  h1:first-of-type {
    color: ${({ theme }) => theme.edukRed};
  }

  .mainContent {
    border: 1px solid #bdbdbd;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    grid-area: card;
    margin-bottom: 2em;
    display: grid;
    grid-template-areas:
      'subtitle subtitle'
      'form form'
      'actions actions';
    grid-template-rows: 3em 1fr 4.25em;

    grid-template-columns: 11.5em 1fr;

    h3:first-of-type {
      grid-area: subtitle;
      margin: 1.5em 0 -0.5em 1.2em;
    }
  }
`
